@font-face {
    font-family: "AGENCYB";
    src: url("../../../../public/fonts/puma-global/AGENCYB.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/AGENCYB.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "American-Captain";
    src: url("../../../../public/fonts/puma-global/American-Captain.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/American-Captain.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "DINEngschriftStd";
    src: url("../../../../public/fonts/puma-global/DINEngschriftStd.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/DINEngschriftStd.woff") format("woff"),
    url("../../../../public/fonts/puma-global/DINEngschriftStd.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "ElevenStriker";
    src: url("../../../../public/fonts/puma-global/ElevenStriker.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/ElevenStriker.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Hexafont-Bold";
    src: url("../../../../public/fonts/puma-global/Hexafont-Bold.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/Hexafont-Bold.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Nature-of-Believing";
    src: url("../../../../public/fonts/puma-global/Nature-of-Believing.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/Nature-of-Believing.woff") format("woff"),
    url("../../../../public/fonts/puma-global/Nature-of-Believing.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Scenario-Bold";
    src: url("../../../../public/fonts/puma-global/Scenario-Bold.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/Scenario-Bold.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Tradition-1893-Bold";
    src: url("../../../../public/fonts/puma-global/Tradition-1893-Bold.woff2") format("woff2"),
    url("../../../../public/fonts/puma-global/Tradition-1893-Bold.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}
