@font-face {
    font-family: "Century731-BT-Bold";
    src: url("../../../../public/fonts/defil/Century731-BT-Bold.woff2") format("woff2"),
    url("../../../../public/fonts/defil/Century731-BT-Bold.woff") format("woff"),
    url("../../../../public/fonts/defil/Century731-BT-Bold.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Collegium";
    src: url("../../../../public/fonts/defil/Collegium.woff2") format("woff2"),
    url("../../../../public/fonts/defil/Collegium.woff") format("woff"),
    url("../../../../public/fonts/defil/Collegium.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "English111-AdagioBT";
    src: url("../../../../public/fonts/defil/English111-AdagioBT.woff2") format("woff2"),
    url("../../../../public/fonts/defil/English111-AdagioBT.woff") format("woff"),
    url("../../../../public/fonts/defil/English111-AdagioBT.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "HelveticaLTPro-BoldCond";
    src: url("../../../../public/fonts/defil/HelveticaLTPro-BoldCond.woff2") format("woff2"),
    url("../../../../public/fonts/defil/HelveticaLTPro-BoldCond.woff") format("woff"),
    url("../../../../public/fonts/defil/HelveticaLTPro-BoldCond.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "ImpactURW";
    src: url("../../../../public/fonts/defil/ImpactURW.woff2") format("woff2"),
    url("../../../../public/fonts/defil/ImpactURW.woff") format("woff"),
    url("../../../../public/fonts/defil/ImpactURW.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "NationalChampionCut";
    src: url("../../../../public/fonts/defil/NationalChampionCut.woff2") format("woff2"),
    url("../../../../public/fonts/defil/NationalChampionCut.woff") format("woff"),
    url("../../../../public/fonts/defil/NationalChampionCut.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "ScriptMTStd-Bold";
    src: url("../../../../public/fonts/defil/ScriptMTStd-Bold.woff2") format("woff2"),
    url("../../../../public/fonts/defil/ScriptMTStd-Bold.woff") format("woff"),
    url("../../../../public/fonts/defil/ScriptMTStd-Bold.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}
