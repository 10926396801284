@font-face {
    font-family: "DIN";
    src: url("../../public/fonts/shared/FFDINforPuma.woff2") format("woff2"),
    url("../../public/fonts/shared/FFDINforPuma.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "DIN";
    src: url("../../public/fonts/shared/FFDINforPuma-Bold.woff2") format("woff2"),
    url("../../public/fonts/shared/FFDINforPuma-Bold.woff") format("woff");
    font-style: normal;
    font-weight: bold;

}

@font-face {
    font-family: "DIN";
    src: url("../../public/fonts/shared/FFDINforPuma-Light.woff2") format("woff2"),
    url("../../public/fonts/shared/FFDINforPuma-Light.woff") format("woff");
    font-style: normal;
    font-weight: 200;
}
