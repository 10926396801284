@font-face {
    font-family: "Army";
    src: url("../../../../public/fonts/puma/Army.woff2") format("woff2"),
    url("../../../../public/fonts/puma/Army.woff") format("woff"),
    url("../../../../public/fonts/puma/Army.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "BOD_B";
    src: url("../../../../public/fonts/puma/BOD_B.woff2") format("woff2"),
    url("../../../../public/fonts/puma/BOD_B.woff") format("woff"),
    url("../../../../public/fonts/puma/BOD_B.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Cen725b";
    src: url("../../../../public/fonts/puma/Cen725b.woff2") format("woff2"),
    url("../../../../public/fonts/puma/Cen725b.woff") format("woff"),
    url("../../../../public/fonts/puma/Cen725b.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Crilleei";
    src: url("../../../../public/fonts/puma/Crilleei.woff2") format("woff2"),
    url("../../../../public/fonts/puma/Crilleei.woff") format("woff"),
    url("../../../../public/fonts/puma/Crilleei.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "HANDGOTB";
    src: url("../../../../public/fonts/puma/HANDGOTB.woff2") format("woff2"),
    url("../../../../public/fonts/puma/HANDGOTB.woff") format("woff"),
    url("../../../../public/fonts/puma/HANDGOTB.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "HORATIOB";
    src: url("../../../../public/fonts/puma/HORATIOB.woff2") format("woff2"),
    url("../../../../public/fonts/puma/HORATIOB.woff") format("woff"),
    url("../../../../public/fonts/puma/HORATIOB.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "impact";
    src: url("../../../../public/fonts/puma/impact.woff2") format("woff2"),
    url("../../../../public/fonts/puma/impact.woff") format("woff"),
    url("../../../../public/fonts/puma/impact.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Nature-of-Believing";
    src: url("../../../../public/fonts/puma/Nature-of-Believing.woff2") format("woff2"),
    url("../../../../public/fonts/puma/Nature-of-Believing.woff") format("woff"),
    url("../../../../public/fonts/puma/Nature-of-Believing.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "ROCKB";
    src: url("../../../../public/fonts/puma/ROCKB.woff2") format("woff2"),
    url("../../../../public/fonts/puma/ROCKB.woff") format("woff"),
    url("../../../../public/fonts/puma/ROCKB.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "Swiss721Bold";
    src: url("../../../../public/fonts/puma/Swiss721Bold.woff2") format("woff2"),
    url("../../../../public/fonts/puma/Swiss721Bold.woff") format("woff"),
    url("../../../../public/fonts/puma/Swiss721Bold.otf") format("opentype");
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: "ShimanoRoundLightNarrow";
    src: url("../../../../public/fonts/puma/ShimanoRoundLightNarrow.woff2") format("woff2"),
    url("../../../../public/fonts/puma/ShimanoRoundLightNarrow.woff") format("woff");
    font-style: normal;
    font-weight: normal;
}
